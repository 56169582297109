<template>
  <figure class="highlight">
    <b-spinner label="Loading..." v-if="loading"></b-spinner>

    <b-container class="mt-6" style="padding-top: 2rem" fluid v-if="!loading">
      <h3 class="py-2">
        <span class="text-capitalize">Custom Documents</span>
      </h3>
      <!-- <b-alert show variant="danger" v-if="!canOrder"
        >&nbsp; {{ orderWarning }} &nbsp;
        <b-button v-b-toggle.collapse-1 variant="primary"
          >View Invoices</b-button
        ></b-alert
      >-->

      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>Status updated successfully</p>
        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>

      <b-alert
        :show="dismissCountDownFail"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChangedFail"
      >
        <p>Error updating status</p>
        <b-progress
          variant="danger"
          :max="dismissSecs"
          :value="dismissCountDownFail"
          height="4px"
        ></b-progress>
      </b-alert>

      <div>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <p class="card-text">Collapse contents Here</p>
            <b-button v-b-toggle.collapse-1-inner size="sm"
              >Toggle Inner Collapse</b-button
            >
            <b-collapse id="collapse-1-inner" class="mt-2">
              <b-card>Hello!</b-card>
            </b-collapse>
          </b-card>
        </b-collapse>
      </div>

      <div class="orders">
        <p class="text-center mt-5" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </p>
        <div class="p-5" v-else>
          <b-nav-form>
            <b-form-input
              class="mr-sm-2"
              placeholder="Filter"
              v-model="filter"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              v-if="filter"
              @click="filter = ''"
              >Clear</b-button
            >
          </b-nav-form>
          <b-table
            show-empty
            small
            stacked="md"
            :items="pendingOrders"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            class="mt-3"
          >
            <template v-slot:cell(actions)="row">
              <b-button
                size=""
                @click="handleClick(row.item.TNLOrderNo)"
                class="mr-1 btn-black"
                variant="danger"
                >Attach Docs
              </b-button>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-pagination"
          ></b-pagination>

          <hr />
        </div>
        <!-- title="'Order Confirmation - ' + clickedCoupon.OrderNumber" -->
        <b-modal
          v-model="detailsModal"
          size="xl"
          :title="'Custom documents for Order - ' + clickedCoupon.TNLOrderNo"
          hideFooter
          centered
        >
          <b-tabs v-model="tabIndex" small card>
            <b-tab title="Order Information">
              <table class="car-options mt-4">
                <!-- <tr>
                <th>Order Number</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderNumber }}</td>
                </tr>-->
                <tr>
                  <th>Order Date</th>
                  <td>:</td>
                  <td>{{ clickedCoupon.OrderDate }}</td>
                </tr>
                <tr>
                  <th>Portal Order Number</th>
                  <td>:</td>
                  <td>{{ clickedCoupon.OrderNo }}</td>
                </tr>
                <tr>
                  <th>TNL Order Number</th>
                  <td>:</td>
                  <td>{{ clickedCoupon.TNLOrderNo }}</td>
                </tr>
              </table>

              <br />

              <table
                class="
                  table
                  b-table
                  cart-table
                  table-striped table-bordered table-hover
                "
              >
                <thead>
                  <tr>
                    <th>VIN</th>
                    <th>Model No</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    role="row"
                    v-for="(item, i) in clickedCoupon.Chasis"
                    :key="i"
                  >
                    <td>{{ item.Serial_No }}</td>
                    <td>{{ item.Item_No }}</td>
                    <td>{{ item.Description }}</td>
                  </tr>
                </tbody>
              </table>
              <br />
              <div class="text-right">
                <b-button variant="danger" @click="tabIndex++">Next</b-button>
              </div>
            </b-tab>
            <b-tab title="Custom docs">
              <span
                class="text-danger label label-danger"
                v-if="displayErrorMessage"
              >
                Please fill all required fields with *, Documents should be PDF
                or Word
              </span>

              <table class="car-options mt-4">
                <tr>
                  <th style="width: 12%">Document Year *</th>
                  <td>:</td>
                  <td style="width: 20%">
                    <select
                      class="mr-sm-2 form-control"
                      v-model="selectedorder.DocumentYear"
                    >
                      <option
                        v-for="(item, index) in orderYears"
                        :value="item.Name"
                        :key="index"
                        >{{ item.Name }}</option
                      >
                    </select>
                  </td>
                  <th style="width: 12%">Document Month *</th>
                  <td>:</td>
                  <td style="width: 20%">
                    <select
                      class="mr-sm-2 form-control"
                      v-model="selectedorder.DocumentMonth"
                    >
                      <option></option>
                      <option value="JANUARY">January</option>
                      <option value="FEBRUARY">February</option>
                      <option value="MARCH">March</option>
                      <option value="APRIL">April</option>
                      <option value="MAY">May</option>
                      <option value="JUNE">June</option>
                      <option value="JULY">July</option>
                      <option value="AUGUST">August</option>
                      <option value="SEPTEMBER">September</option>
                      <option value="OCTOBER">October</option>
                      <option value="NOVEMBER">November</option>
                      <option value="DECEMBER">December</option>
                    </select>
                  </td>
                  <td>
                    <b-button variant="danger" @click="fetchDocs">
                      <span v-if="IsFetchingDocs">Please wait</span>
                      <span v-else>Fetch Document</span>
                    </b-button>
                  </td>
                </tr>

                <tr>
                  <td colspan="3" style="vertical-align: top;">
                    <table
                      v-if="fetchedFiles.length > 0"
                      class="
                  table
                  b-table
                  cart-table
                  table-striped table-bordered table-hover
                "
                    >
                      <tr>
                        <th style="width:5%"></th>
                        <th style="width:95%">File Name</th>
                      </tr>
                      <tr v-for="item in fetchedFiles" :key="item.Name">
                        <td>
                          <input
                            type="checkbox"
                            ref="theCheckbox"
                            @name="
                              {
                                {
                                  item.Name;
                                }
                              }
                            "
                            @change="chooseFile(item.Name)"
                          />
                        </td>
                        <td style="width:95%">
                          <a
                            :href="
                              getPath(
                                selectedorder.DocumentYear,
                                selectedorder.DocumentMonth,
                                item.Name
                              )
                            "
                            target="_blank"
                          >
                            {{ item.Name }}</a
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td
                    colspan="3"
                    v-if="selectedFiles.length > 0"
                    style="vertical-align: top;"
                  >
                    <h4>Selected Files</h4>
                    <table
                      v-if="selectedFiles.length > 0"
                      class="
                  table
                  b-table
                  cart-table
                  table-striped table-bordered table-hover
                "
                    >
                      <tr>
                        <th>Year</th>
                        <th>Month</th>
                        <th>File</th>
                        <th></th>
                      </tr>
                      <tr v-for="(item, i) in selectedFiles" :key="i">
                        <td>{{ item.Year }}</td>
                        <td>{{ item.Month }}</td>
                        <td>
                          <a
                            :href="getPath(item.Year, item.Month, item.File)"
                            target="_blank"
                          >
                            {{ item.File }}
                          </a>
                        </td>
                        <td>
                          <span class="remove" @click="removeItem(i)"
                            >&times;</span
                          >
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td>
                    <b-button
                      variant="danger"
                      @click="saveDocuments"
                      v-if="selectedFiles.length > 0"
                    >
                      Save Documents
                    </b-button>
                  </td>
                </tr>
              </table>

              <!-- <div class="text-left">
                <b-button variant="danger" @click="tabIndex--"
                  >Previous</b-button
                >
              </div>
              <div class="text-right">
                <b-button
                  variant="danger"
                  :disabled="confirmPayment"
                  @click="saveInfo"
                >
                  <span v-if="confirmPayment">Please wait</span>
                  <span v-else>Save</span>
                </b-button>
              </div> -->
            </b-tab>
          </b-tabs>
        </b-modal>
      </div>
    </b-container>
  </figure>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
import FileService from "../../service/fileUpload";
import VehicleService from "../../service/vehicle";
import { getData } from "./../../utils/storage";

export default {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDownFail: 0,
      tabIndex: 0,
      fields: [
        {
          key: "sNo",
          label: "",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "OrderDate",
          label: "Order Date",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "OrderNo",
          label: "Dealer Order Number",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "TNLOrderNo",
          label: "TNL Order Number",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "CustomerName",
          label: "Customer",
          sortable: true,
          sortDirection: "desc"
        } /*,
        {
          key: "OwnersName",
          label: "Time Left",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "PaymentStatus",
          label: "Status",
          sortable: true,
          sortDirection: "desc"
        }*/,
        { key: "actions", label: "Actions" }
      ],
      gettingCoupons: true,
      clickedCoupon: {},
      detailsModal: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dealerid: 0,
      pendingOrders: [],
      orderYears: [],
      pendingOrderDetails: [],
      loading: false,
      selectedorder: {},
      document: null,
      confirmPayment: false,
      displayErrorMessage: false,
      canOrder: true,
      IsFetchingDocs: false,
      fetchedFiles: [],
      selectedFiles: []
    };
  },

  created() {
    this.hello();
    const loggedInUser = getData("userInfo");
    this.dealerid = loggedInUser.DealerID;
    this.getripeOrders();
    this.getOrderYears();
  },
  computed: {
    totalUnit() {
      let totalUnit = this.clickedCoupon.orderDetails
        ? this.clickedCoupon.orderDetails.reduce(
            (accumulator, currentValue) => {
              return parseInt(currentValue.QuantityRequested) + accumulator;
            },
            0
          )
        : 0;
      return totalUnit;
    },
    totalLessVATAndDiscount() {
      let totalLessVATAndDiscount = this.clickedCoupon.orderDetails
        ? this.clickedCoupon.orderDetails.reduce(
            (accumulator, currentValue) => {
              return (
                parseInt(currentValue.QuantityRequested) *
                  parseInt(currentValue.UnitPrice) +
                accumulator
              );
            },
            0
          )
        : 0;
      return totalLessVATAndDiscount;
    }
  },
  methods: {
    removeItem(index) {
      console.log(this.selectedFiles);
      this.selectedFiles.splice(index, 1);
      console.log(index);
    },
    getPath: function(year, month, filename) {
      var retvalue =
        "https://toyotang.sharepoint.com/sites/TNL/operation%20and%20logistics/Customs%20Paper/" +
        year +
        "/" +
        month +
        "/" +
        filename;
      return retvalue;
    },
    chooseFile: async function(filename) {
      var items = this.$refs.theCheckbox;
      /*console.log(items);
      
      for (var i = 0; i < items.length; i++) {
        console.log(items[i] + filename);
      }
      */
      items.forEach(async element => {
        if (element.checked) {
          // console.log(element.id);
          // console.log(filename);
          var file = {
            Year: this.selectedorder.DocumentYear,
            Month: this.selectedorder.DocumentMonth,
            File: filename
          };

          var index = this.selectedFiles.findIndex(
            x =>
              x.File == file.File &&
              x.Year == file.Year &&
              x.Month == file.Month
          );

          if (index === -1) {
            file.Index = this.selectedFiles.length + 1;
            this.selectedFiles.push(file);
          }
        }
      });

      // if (this.$refs.theCheckbox.checked) {
      //   alert("You know JS");
      // } else {
      //   alert("You don't know JS");
      // }
    },
    fetchDocs: async function() {
      this.IsFetchingDocs = true;
      this.fetchedFiles = [];
      var param =
        this.selectedorder.DocumentYear +
        "|" +
        this.selectedorder.DocumentMonth;
      const { data } = await VehicleService.getOrderFiles(param);
      this.fetchedFiles = data;

      this.IsFetchingDocs = false;
    },
    showToast(variant = "danger") {
      this.$bvToast.toast(
        "You have overdue invoices." + this.orderNoteWarning,
        {
          title: `Overdue Invoices`,
          variant: variant,
          solid: true,
          noAutoHide: true
        }
      );
    },
    getOrderYears: async function() {
      this.loading = true;
      const { data } = await VehicleService.getOrderYears();
      this.orderYears = data;
    },
    getripeOrders: async function() {
      this.loading = true;
      const { data } = await VehicleService.getOrdersRipeForLogistics();
      this.loading = false;
      console.log("data is:, ", data);
      this.pendingOrders = data.Data;
      this.pendingOrderDetails = data.Data.OrderDetails;
      this.totalRows = this.pendingOrders.length;
    },
    saveDocuments: async function() {
      var items = [];
      this.selectedFiles.forEach(async element => {
        var item = {
          OrderNumber: this.clickedCoupon.TNLOrderNo,
          DocumentLink: this.getPath(element.Year, element.Month, element.File),
          ItemNo: ""
        };
        items.push(item);
      });
      VehicleService.saveCustomDocs(items);
      console.log("dsdsds");
    },
    handleClick(id) {
      this.clickedCoupon = this.pendingOrders.filter(
        n => n.TNLOrderNo === id
      )[0];
      console.log(this.clickedCoupon);
      this.detailsModal = true;
      this.tabIndex = 0;
      /*
      this.clickedCoupon.orderDetails = this.pendingOrderDetails.filter(
        n => n.OrderID === id
      );
      console.log(this.clickedCoupon);
      this.selectedorder.OrderID = id;
      this.selectedorder.PaymentStatus = 0;
      this.detailsModal = true;
      this.tabIndex = 0;
      */
    },
    removeOrder(orderid) {
      let orders = this.pendingOrders;
      this.pendingOrders = orders.filter(function(obj) {
        return obj.OrderID !== orderid;
      });
    },
    saveInfo: async function() {
      this.displayErrorMessage = false;
      if (!this.checkPaymentForm()) {
        this.displayErrorMessage = true;
        return;
      }
      this.confirmPayment = true;
      if (this.checkFileRule(this.document)) {
        const formData = new FormData();
        formData.append(`document`, this.document);
        const uploadresponse = await FileService.uploadFile(formData);
        console.log("upload response path is:", uploadresponse);
        this.selectedorder.PaymentProofURL = uploadresponse
          ? uploadresponse.data.Path
          : "";
      }

      console.log("Selected Order: ", this.selectedorder);
      try {
        const { data } = await VehicleService.updateOrderStatus(
          this.selectedorder
        );
        if (data.Data) {
          this.detailsModal = false;
          this.showSuccessAlert();
          this.removeOrder(this.selectedorder.OrderID);
          this.selectedorder = {};
          this.confirmPayment = false;
        } else {
          this.showFailureAlert();
        }
        console.log("data: ", data);
      } catch (ex) {
        this.showFailureAlert();
      }
      this.loading = false;
      // this.detailsModal = false;
    },
    checkPaymentForm() {
      if (!this.selectedorder) {
        return false;
      }
      if (
        !this.selectedorder.PaymentMode ||
        !this.selectedorder.AmountPaid ||
        !this.selectedorder.DealerBank ||
        !this.selectedorder.TNLBank
      ) {
        return false;
      }
      return true;
    },
    checkFileRule(file) {
      return /\.(pdf|doc|docx|PDF|DOC|DOCX)$/.test(file.name);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedFail(dismissCountDown) {
      this.dismissCountDownFail = dismissCountDown;
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showFailureAlert() {
      this.dismissCountDownFail = this.dismissSecs;
    }
  },
  filters: {},
  watch: {}
};
</script>
<style scoped>
.wizard-navigation .stepTitle span {
  font-size: 2rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.mt-6 {
  margin-top: 4rem !important;
}
</style>

.wizard-navigation .stepTitle span { font-size: 2rem !important; font-weight:
500; line-height: 1.2; } .mt-6 { margin-top: 4rem !important; }
